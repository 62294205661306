import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/adtopografica-site/adtopografica-site/src/components/LayoutMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Premessa`}</h3>
    <p>{`In ottemperanza degli obblighi derivanti dalla normativa nazionale (D. Lgs 30 giugno 2003 n. 196, Codice in materia di protezione dei dati personali) e comunitaria, (Regolamento europeo per la protezione dei dati personali n. 679/2016, GDPR) e successive modifiche, il presente sito rispetta e tutela la riservatezza dei visitatori e degli utenti, ponendo in essere ogni sforzo possibile e proporzionato per non ledere i diritti degli utenti.`}</p>
    <h3>{`Dati personali`}</h3>
    <p>{`Questo sito non raccoglie dati personali. L'invio facoltativo e volontario di e-mail agli indirizzi presenti nella sezione dei contatti comporta l'acquisizione dell'indirizzo mail del mittente che verrà utilizzato solo per l'eventuale risposta e non verrà comunicato a terzi.`}</p>
    <h3>{`Cookie`}</h3>
    <p>{`Nessun dato personale degli utenti viene acquisito dal sito, che utilizza esclusivamente cookie tecnici. Non viene fatto uso di cookie per la trasmissione di informazioni di carattere personale, né vengono utilizzati cookie di profilazione o di terze parti. Pertanto non è chiesto alcun tipo di consenso da parte dell'utente`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      